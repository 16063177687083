import React, { useState, useEffect } from 'react';
import DoubleBonus from './DoubleBonus';
import Rules from './RulesDialog';
import '../css/BonusCards.css';
import { useSnackbarWithApiRequest } from '../helper/apiHelper';
import { useBonus } from '../context/BonusContext';
import { useStyles } from '../context/StyleContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDisableDuration } from '../context/DisableDurationContext';
import config from '../config';
import RequestConfirmDialog from './RequestConfirmDialog';

const BonusCards = ({ selectedTab }) => {
  const { bonusData, loading, error } = useBonus();
  const [isOpen, setIsOpen] = useState(false); // DoubleBonus için modal state
  const [isRulesOpen, setIsRulesOpen] = useState(false); // RulesDialog için modal state
  const [hoveredCard, setHoveredCard] = useState(null);
  const [currentBonus, setCurrentBonus] = useState(null);
  const [bonusImg, setBonusImg] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState(null);
  const styles = useStyles();
  const [iconSize, setIconSize] = useState(30); // Varsayılan PC boyutu


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIconSize(25); // Mobil boyutu
      } else {
        setIconSize(35); // PC boyutu
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Sayfa yüklendiğinde boyutu kontrol et

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useSnackbarWithApiRequest hook'unu kullan
  const { SnackbarAlert, sendBonusRequest, LoadingBackdrop } = useSnackbarWithApiRequest();
  const { disableDuration } = useDisableDuration();

  // useEffect her zaman çağrılır, modal açık olduğunda body scroll'unu kapatır
  useEffect(() => {
    if (isOpen || isRulesOpen) {
      document.body.style.overflow = 'hidden'; // Modal açıldığında scroll'u kapat
    } else {
      document.body.style.overflow = 'auto'; // Modal kapandığında scroll'u geri getir
    }

    // Cleanup function: Modal kapandığında scroll'u geri aç
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, isRulesOpen]); // isOpen ve isRulesOpen her değiştiğinde çalışacak

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;

  const openModal = () => setIsOpen(true);
  const openRulesModal = () => setIsRulesOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setIsRulesOpen(false);
  };


  const handleTalepEt = (bonus) => {
    if (bonus.data.length > 1) {
      setBonusImg(bonus.bonus_gorsel)
      console.log(bonusImg)
      setCurrentBonus(bonus);
      openModal();
    } else {
      
      setSelectedBonus(bonus);
      setIsConfirmDialogOpen(true);
    }
  };

  const handleConfirmRequest = () => {
    setIsConfirmDialogOpen(false);
    sendBonusRequest(selectedBonus.data[0].process_name);
  };

  // Kurallar butonuna tıklandığında çalışacak fonksiyon
  const handleKurallar = (bonus) => {
    setCurrentBonus(bonus);
    openRulesModal();
  };

  // Bonusları filtreleme
  const filteredBonuses = selectedTab === 'Tümü'
    ? bonusData
    : bonusData.filter(bonus =>
      bonus.bonus_type
        .split(', ')
        .map(type => type.toLowerCase())
        .includes(selectedTab.toLowerCase())
    );


  return (
    <div className="bonus-cards-container">
      {filteredBonuses.map((data, index) => (
        <div
          key={index}
          className="bonus-card"
          onMouseEnter={() => setHoveredCard(index)}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <img
            src={`${config.imgUrl}${data.bonus_gorsel}`}
            className={`bonus-image ${hoveredCard === index ? 'hovered' : ''}`}
            alt="Bonus Görsel"
          />

          <div className="info-icon" onClick={() => handleKurallar(data)}>
            <InfoOutlinedIcon style={{
              fontSize: iconSize,
              color: styles.BonusCard.InformationIcon?.IconColor || "#e5b645ff"
            }} />
          </div>

          {hoveredCard === index && (
            <div className="button-group" >
              <button
                onClick={() => handleTalepEt(data)}
                className={`btn talep-btn ${(disableDuration && data.data.length <= 1) || data.data.length <= 1 && data.data?.[0]?.is_bakim
                  ? 'disabled'
                  : ''
                  }`}
                disabled={Boolean(
                  (disableDuration && data.data.length <= 1) || data.data.length <= 1 && data.data?.[0]?.is_bakim
                )} // Disable, bakımda veya disableDuration aktifse
                style={{
                  backgroundColor:
                    (disableDuration && data.data.length <= 1) || data.data.length <= 1 && data.data?.[0]?.is_bakim
                      ? '#ccc'
                      : styles.BonusCard.RequestButton?.backgroundColor || '#e5b645',
                  color:
                    (disableDuration && data.data.length <= 1) || data.data.length <= 1 && data.data?.[0]?.is_bakim
                      ? '#666'
                      : styles.BonusCard.RequestButton?.TextColor || data.data.length <= 1 && '#1d1d1d',
                  cursor:
                    (disableDuration && data.data.length <= 1) || data.data.length <= 1 && data.data?.[0]?.is_bakim
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                {data.data?.[0]?.is_bakim && data.data.length <= 1
                  ? 'Bakımda'
                  : disableDuration && data.data.length <= 1
                    ? `Lütfen ${disableDuration} saniye bekleyin`
                    : 'Talep Et'}
              </button>

              <button
                onClick={() => handleKurallar(data)}
                className="btn kurallar-btn"
                style={{ backgroundColor: styles.BonusCard.RulesButton?.backgroundColor || "#1d1d1d", color: styles.BonusCard.RulesButton?.TextColor || "#fff" }}
              >
                Kurallar
              </button>
            </div>
          )}
        </div>
      ))}

      {isOpen && currentBonus && (
        <div className="modal-overlay-doublebonus" onClick={closeModal}>
          <div className="modal-content-doublebonus" onClick={(e) => e.stopPropagation()}>
            <DoubleBonus closeModal={closeModal} bonusData={currentBonus.data} bonusImage={bonusImg} />
          </div>
        </div>
      )}


      {isRulesOpen && currentBonus && (
        <div className="modal-overlay-doublebonus" onClick={closeModal}>
          <div className="modal-content-doublebonus" onClick={(e) => e.stopPropagation()}>
            <Rules closeModal={closeModal} bonusData={currentBonus.data} />
          </div>
        </div>
      )}

      <RequestConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        bonusName={selectedBonus?.data[0]?.bonus_name || ''}
        bonusImage={`${config.imgUrl}${selectedBonus?.bonus_gorsel}`}
        onConfirm={handleConfirmRequest}
      />


      {SnackbarAlert}
      {LoadingBackdrop}
    </div>
  );
};

export default BonusCards;
