import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';

const RequestConfirmDialog = ({ open, onClose, bonusName, bonusImage, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: '#1d1d1d',
          color: '#fff',
          textAlign: 'center',
          padding: 2,
        },
      }}
    >
      {/* Başlık */}
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '20px',
          color: '#fff',
          textTransform: 'uppercase',
          paddingBottom: 1,
        }}
      >
        {bonusName}
      </DialogTitle>

      {/* Görsel ve Metin */}
      <DialogContent>
        <Box
          component="img"
          src={bonusImage}
          alt="Bonus Görsel"
          sx={{
            width: '100%',
            maxWidth: '200px',
            margin: '0 auto 16px',
            borderRadius: 1,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
          }}
        />
        <Typography variant="body1" sx={{ fontSize: '16px', color: '#e5e5e5', marginBottom: 2 }}>
          Talebinizi onaylıyor musunuz?
        </Typography>
      </DialogContent>

      {/* Aksiyonlar */}
      <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="error"
          sx={{
            fontWeight: 'bold',
            borderColor: '#d32f2f',
            color: '#d32f2f',
            ':hover': { backgroundColor: '#f8d7da', borderColor: '#d32f2f' },
          }}
        >
          İptal
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="success"
          sx={{
            fontWeight: 'bold',
            ':hover': { backgroundColor: '#388e3c' },
          }}
        >
          Onayla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RequestConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bonusName: PropTypes.string.isRequired,
  bonusImage: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RequestConfirmDialog;
