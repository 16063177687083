import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import HomePage from './components/HomePage';
import { BonusProvider } from './context/BonusContext';
import { RequestHistoryProvider } from './context/RequestHistoryContext';
import { StyleProvider } from './context/StyleContext';
import { StaticProvider, useStatic } from './context/StaticContext';
import { MaintenanceProvider } from './context/MaintenanceContext';

const App = () => {
  return (
    <StaticProvider>
      <MaintenanceProvider>
        <ReCaptchaWrapper />
      </MaintenanceProvider>
    </StaticProvider>
  );
};

const ReCaptchaWrapper = () => {
  const { captchaKey, title } = useStatic();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={captchaKey || "6LeYMw0qAAAAAPxOvCyJXad2D75aScLa3ZEvO8lq"}
      scriptProps={{
        appendTo: "head",
      }}
    >
      <StyleProvider>
        <BonusProvider>
          <RequestHistoryProvider>
            <HomePage />
          </RequestHistoryProvider>
        </BonusProvider>
      </StyleProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
