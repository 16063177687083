import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../config";

const StyleContext = createContext();

export const StyleProvider = ({ children }) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await fetch(
          `${config.apiBaseUrl}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              process_id: "command",
              user_id: "1",
              cmd: "get-css"
            }),      
          },
        );
        const data = await response.json();
        setStyles(data.styles);
      } catch (error) {
        console.error("Error fetching styles:", error);
      }
    };

    fetchStyles();
  }, []);

  return (
    <StyleContext.Provider value={styles}>
      {children}
    </StyleContext.Provider>
  );
};

export const useStyles = () => useContext(StyleContext);
