import React, { createContext, useState, useContext, useCallback } from 'react';
import config from '../config';
import { getUserIdFromUrl } from '../helper/apiHelper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMaintenanceMode } from './MaintenanceContext';

// Context oluştur
const BonusContext = createContext();

// Bonus verisini sağlayacak context provider
export const BonusProvider = ({ children }) => {
  const [bonusData, setBonusData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = getUserIdFromUrl();
  const [userName, setUserName] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isMaintenanceMode } = useMaintenanceMode(); // Bakım modu bilgisini al

  const fetchBonusForUser = useCallback(async () => {
    let success = false;
    let attempts = 0;
    const maxAttempts = 4;
    const timeout = 5000;

    const fetchWithTimeout = (url, options, timeout) => {
      return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Request timeout")), timeout)
        ),
      ]);
    };

    while (!success && attempts < maxAttempts) {
      if (!executeRecaptcha) {
        attempts += 1;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        continue;
      }

      try {
        const token = await executeRecaptcha("foruserbonus");
        if (userId) {
          // İstek payload'ını oluştur
          const payload = {
            process_id: "command",
            user_id: userId,
            recaptcha: token,
            cmd: "getc-bonuses",
          };

          // Bakım modu kontrolü
          if (isMaintenanceMode) {
            payload.dev = "10"; // Ek "dev" parametresini ekle
          }

          const response = await fetchWithTimeout(
            `${config.apiBaseUrl}`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(payload),
            },
            timeout
          );

          const result = await response.json();

          if (result.status) {
            setUserName(result.full_name);
            setBonusData(result.data.talep_data); // Bonus verisini state'e kaydet
            setTabs(result.data.bonus_type_tabs);
            success = true;
            setLoading(false);
          } else if (result.message === "Bilinmeyen kullanıcı") {
            setLoading(true);
            console.log(result.message);
            return;
          } else {
            setUserName("Kullanıcı");
            setLoading(true);
          }
        } else {
          setLoading(true);
        }
      } catch (error) {
        console.error("İstek sırasında hata oluştu:", error);
        setUserName("Kullanıcı");
        setLoading(true);
      }
      attempts += 1;
    }
  }, [executeRecaptcha, userId, isMaintenanceMode]);

  // Bonus verisi, loading durumu ve hata bilgisini sağlayan context
  return (
    <BonusContext.Provider value={{ bonusData, loading, tabs, fetchBonusForUser, userName, userId }}>
      {children}
    </BonusContext.Provider>
  );
};

// Context'teki veriye erişmek için kullanılacak özel hook
export const useBonus = () => useContext(BonusContext);
