
import React, { createContext, useContext, useState, useEffect } from 'react';

const MaintenanceContext = createContext();

// Provider bileşeni
export const MaintenanceProvider = ({ children }) => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    // URL'den "bakim" parametresini oku
    const queryParams = new URLSearchParams(window.location.search);
    const bakim = queryParams.get('bakim');
    if (bakim === 't') {
      setIsMaintenanceMode(true);
    }
  }, []);

  return (
    <MaintenanceContext.Provider value={{ isMaintenanceMode }}>
      {children}
    </MaintenanceContext.Provider>
  );
};

// Custom hook
export const useMaintenanceMode = () => useContext(MaintenanceContext);
