import React, { useEffect, useState } from "react";
import Header from "./Header";
import RequestHistoryButton from "./RequestHistoryButton";
import Tabs from "./Tabs";

import { CircularProgress } from "@mui/material";
import BonusCards from "./BonusCards";
import { useStyles } from "../context/StyleContext";
import { DisableDurationProvider } from "../context/DisableDurationContext";
import MaintenanceMode from "./MaintenanceMode"; // Yeni bileşeni import ediyoruz
import '../css/HomePage.css';
import { useStatic } from "../context/StaticContext";
import { useBonus } from "../context/BonusContext";

const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState('Tümü');
  const styles = useStyles();
  const { updateMode } = useStatic();
  const { fetchBonusForUser, userName, loading, userId } = useBonus();



  useEffect(() => {
    fetchBonusForUser();
  }, [fetchBonusForUser]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  if (updateMode) {
    return <MaintenanceMode />;
  }

  if (loading || !userId) {
    return (
      <div className="loading-container" style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="homepage-container" style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
      <Header userName={userName} />

      <div className="tabs-request-container">
        <Tabs onTabChange={handleTabChange} selectedTab={selectedTab} />
        <RequestHistoryButton />
      </div>

      <DisableDurationProvider>
        <div className="bonus-card-container-2">
          <BonusCards selectedTab={selectedTab} />
        </div>
      </DisableDurationProvider>

      <a
        href="https://hookdijital.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="hook-link"
      >
        <img src="./images/image7.png" alt="Hook logo" className="hook-logo" />
      </a>
    </div>
  );
};

export default HomePage;
