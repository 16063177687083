const config = {
    development: {
      apiBaseUrl: 'https://test.casinova.co/api/',
      appName: 'OrisBetBonus',
      jsonUrl: 'https://test.casinova.co/panel-data.json',
      cssJsonUrl: 'https://test.casinova.co/configCSS.json',
      imgUrl: 'https://test.casinova.co'
    },
    production: {
      apiBaseUrl: 'api/',
      appName: 'OrisBetBonus',
      jsonUrl: '/panel-data.json',
      cssJsonUrl: '/configCSS.json',
      imgUrl: ''
    },
  };
  
  const env = 'production';
  export default config[env];
  