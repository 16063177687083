import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../config";

const StaticContext = createContext();

export const StaticProvider = ({ children }) => {
    const [wheelURL, setWheelURL] = useState("");
    const [updateMode, setUpdateMode] = useState(false);
    const [captchaKey, setCaptchaKey] = useState("");
    const [title, setTitle] = useState("HookOtoBonus")

  useEffect(() => {
    const fetchStatic = async () => {
      try {
        const response = await fetch(
          `${config.apiBaseUrl}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              process_id: "command",
              user_id: "1",
              cmd: "get-static"
            }),      
          },
        );
        const data = await response.json();
        setTitle(data.title)
        setCaptchaKey(data.captcha_key)
        setUpdateMode(data.bakim)
        setWheelURL(data.spinWheelURL)
      } catch (error) {
        console.error("Error fetching styles:", error);
      }
    };

    fetchStatic();
  }, []);

  return (
    <StaticContext.Provider value={{captchaKey, wheelURL, updateMode, title}}>
      {children}
    </StaticContext.Provider>
  );
};

export const useStatic = () => useContext(StaticContext);
